<template>
  <div class="text-center">
    <v-menu v-model="menu" :close-on-content-click="false" rounded offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <mf-button color="primary" icon="filter_list" text label="Mais filtros" size="md" />
        </div>
      </template>

      <v-card class="popover-content">
        <v-card-text class="d-flex flex-column" style="gap: 14px">
          <span class="popover-title">Mais filtros</span>

          <slot></slot>

          <mf-action-buttons
            :secondary-button="{
              text: 'Fechar',
              action: cancel,
              isVisible: true,
              isDisabled: false
            }"
          />
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'PopoverFilters',
  data: () => ({
    menu: false
  }),
  methods: {
    cancel() {
      this.$emit('cancel')
      this.close()
    },
    close() {
      this.menu = false
    }
  }
}
</script>

<style lang="scss" scoped>
.popover-title {
  font-size: 16px;
  font-weight: bold;
  color: #4b525b;
}

.popover-content {
  border-radius: 8px;
  box-shadow: 0px 2px 20px 0px #0000002f;
}
</style>
