var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"close-on-content-click":false,"rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('mf-button',{attrs:{"color":"primary","icon":"filter_list","text":"","label":"Mais filtros","size":"md"}})],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticClass:"popover-content"},[_c('v-card-text',{staticClass:"d-flex flex-column",staticStyle:{"gap":"14px"}},[_c('span',{staticClass:"popover-title"},[_vm._v("Mais filtros")]),_vm._t("default"),_c('mf-action-buttons',{attrs:{"secondary-button":{
            text: 'Fechar',
            action: _vm.cancel,
            isVisible: true,
            isDisabled: false
          }}})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }